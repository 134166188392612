/**
 * Created by jerry on 2020/04/20.
 * 通用次卡价格调整 api
 */
import * as API from '@/api/index'

export default {
    // 获取通用次卡价格调整列表
    generalCardPriceAdjustmentList: params => {
        return API.POST('api/generalCardPriceAdjustment/list', params)
    },
    // 新增通用次卡价格调整
    generalCardPriceAdjustmentCreate: params => {
        return API.POST('api/generalCardPriceAdjustment/create', params)
    },
    // 更新通用次卡价格调整
    generalCardPriceAdjustmentUpdate: params => {
        return API.POST('api/generalCardPriceAdjustment/update', params)
    },
    //删除通用次卡价格调整
    generalCardPriceAdjustmentDelete: params => {
        return API.POST('api/generalCardPriceAdjustment/delete', params)
    },
    //获取全部通用次卡
    generalCardAll: params => {
        return API.POST('api/generalCard/all', params)
    },
}
