<template>
  <div class="content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form
            :inline="true"
            size="small"
            :model="ruleForm"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="通用次卡名称">
              <el-input
                @clear="handleSearch"
                v-model="ruleForm.GeneralCardName"
                placeholder="输入通用次卡名称、别名搜索"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="组织单位">
              <el-input
                @clear="handleSearch"
                v-model="ruleForm.EntityName"
                placeholder="输入组织单位名称搜索"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="handleSearch"
                v-prevent-click
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button
            type="primary"
            size="small"
            @click="showAddDialog"
            v-prevent-click
            >新增</el-button
          >
        </el-col>
      </el-row>
    </div>
    <div class="martp_10">
      <el-table
        v-model="loading"
        :data="generalCardTableData"
        size="small"
        tooltip-effect="light"
      >
        <el-table-column prop="Name" label="通用次卡名称"></el-table-column>
        <el-table-column prop="Alias" label="通用次卡别名"></el-table-column>
        <el-table-column prop="EntityName" label="组织单位"></el-table-column>
        <el-table-column prop="SellPrice" label="销售价格">
          <template slot-scope="scope"
            >￥{{ scope.row.SellPrice | NumFormat }}</template
          ></el-table-column
        >
        <el-table-column prop="Price" label="调整价格">
          <template slot-scope="scope"
            >￥{{ scope.row.Price | NumFormat }}</template
          ></el-table-column
        >
        <el-table-column label="操作" width="145px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="clickEditBtn(scope.row)"
              v-prevent-click
              >编辑</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="clickDeleteBtn(scope.row)"
              v-prevent-click
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 text_right">
        <el-pagination
          background
          v-if="paginations.total > 0"
          @current-change="handleCurrentChange"
          :current-page.sync="paginations.page"
          :page-size="paginations.page_size"
          :layout="paginations.layout"
          :total="paginations.total"
        ></el-pagination>
      </div>
    </div>
    <!--新增、编辑弹出框-->
    <el-dialog
      title="通用次卡价格调整"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <div>
        <el-form
          :model="generalCardruleForm"
          :rules="rules"
          ref="generalCardruleForm"
          label-width="120px"
          size="small"
        >
          <el-col :span="12">
            <el-form-item label="选择通用次卡" prop="GeneralCardID">
              <el-select
                clearable
                v-model="generalCardruleForm.GeneralCardID"
                placeholder="请选择通用次卡"
                filterable
                :disabled="isEdit"
                @change="generalChange"
              >
                <el-option
                  v-for="(item, index) in generalCardAlls"
                  :key="index"
                  :label="item.Name"
                  :value="item.ID"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="适用组织" prop="EntityID">
              <span slot="label">
                适用组织
                <el-popover placement="top-start" width="200" trigger="hover">
                  <p>适用于同级所有节点，则只需选择父节点。</p>
                  <p>比如：适用于所有节点，只需选择“顶级/第一个”节点。</p>
                  <el-button
                    type="text"
                    style="color: #dcdfe6"
                    icon="el-icon-info"
                    slot="reference"
                  ></el-button>
                </el-popover>
              </span>
              <treeselect
                clearable
                :disabled="isEdit"
                v-model="generalCardruleForm.EntityID"
                :options="entityList"
                :normalizer="normalizer"
                clearValueText
                noResultsText="无匹配数据"
                placeholder="选择所属部门"
                class="width_220"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="通用次卡售价">
              <el-input
                v-model="generalCardruleForm.SellPrice"
                :disabled="true"
                class="width_220"
              >
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="调整价" prop="Price">
              <el-input
                v-model="generalCardruleForm.Price"
                palceholder="请输入调整价"
                class="width_220"
                min="0"
                type="number"
                v-enter-number2
              >
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="saveGeneralCard"
          v-loading="modelLoading"
          size="small"
          v-prevent-click
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import APIEntity from "@/api/KHS/Entity/entity";
import API from "@/api/iBeauty/GoodsPrice/generalCardPrice";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: { Treeselect },
  name: "GeneralCardPrice",
  data() {
    return {
      loading: false,
      modelLoading: false,
      generalCardTableData: [], // 表格数据
      dialogVisible: false, // 增加、编辑弹出框状态
      isEdit: false,
      entityList: [], // 组织单位数据
      generalCardAlls: [],
      ruleForm: {
        GeneralCardName: "",
        EntityName: "",
      },
      generalCardruleForm: {
        GeneralCardID: "",
        EntityID: null,
        SellPrice: "",
        Price: "",
      },
      rules: {
        GeneralCardID: [
          { required: true, message: "请选择通用次卡", trigger: "change" },
        ],
        Price: [{ required: true, message: "请填写调整价", trigger: "blur" }],
        EntityID: [
          { required: true, message: "请选择组织", trigger: "change" },
        ],
      }, // 表单验证规则
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      }, //需要给分页组件传的信息
    };
  },
  methods: {
    // 树形结构数据转换
    normalizer(node) {
      return {
        id: node.ID,
        label: node.EntityName,
        children: node.Child,
      };
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.search();
    },
    // 搜索事件
    handleSearch() {
      let that = this;
      that.paginations.page = 1;
      that.search();
    },
    search() {
      let that = this;
      let params = {
        PageNum: that.paginations.page,
        GeneralCardName: that.ruleForm.GeneralCardName,
        EntityName: that.ruleForm.EntityName,
      };
      that.loading = true;
      API.generalCardPriceAdjustmentList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.generalCardTableData = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(() => {
          that.loading = false;
        });
    },
    // 所属单位
    entityData: function () {
      var that = this;
      APIEntity.getEntityAll().then((res) => {
        if (res.StateCode == 200) {
          that.entityList = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 新增按钮点击事件
    showAddDialog() {
      var that = this;
      that.generalCardruleForm = {
        GeneralCardID: "",
        EntityID: null,
        SellPrice: "",
        Price: "",
      };
      that.isEdit = false;
      that.dialogVisible = true;
    },
    // 编辑按钮点击事件
    clickEditBtn(row) {
      var that = this;
      that.generalCardruleForm = Object.assign({}, row);
      that.isEdit = true;
      that.dialogVisible = true;
    },
    // 删除按钮点击事件
    clickDeleteBtn(row) {
      var that = this;
      that
        .$confirm("此操作将永久删除该记录, 是否继续?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let params = {
            GeneralCardID: row.GeneralCardID,
            EntityID: row.EntityID,
          };
          API.generalCardPriceAdjustmentDelete(params).then((res) => {
            if (res.StateCode == 200) {
              that.$message.success("删除成功");
              that.search();
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //选中的值改变
    generalChange(val) {
      let that = this;
      if (val) {
        that.generalCardruleForm.SellPrice =
          that.generalCardAlls[
            that.generalCardAlls.findIndex((p) => p.ID == val)
          ].Price;
      } else {
        that.generalCardruleForm.SellPrice = "";
      }
    },
    // 获取所有通用次卡不分页
    generalCardAll: function () {
      var that = this;
      API.generalCardAll().then((res) => {
        if (res.StateCode == 200) {
          that.generalCardAlls = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //保存通用次卡
    saveGeneralCard() {
      let that = this;
      this.$refs.generalCardruleForm.validate((valid) => {
        if (valid) {
          let params = {
            GeneralCardID: that.generalCardruleForm.GeneralCardID,
            EntityID: that.generalCardruleForm.EntityID,
            Price: that.generalCardruleForm.Price,
          };
          that.modelLoading = true;
          if (that.isEdit) {
            API.generalCardPriceAdjustmentUpdate(params)
              .then((res) => {
                if (res.StateCode == 200) {
                  that.$message.success({
                    message: res.Message,
                    duration: 2000,
                  });
                  that.search();
                } else {
                  that.$message.error({
                    message: res.Message,
                    duration: 2000,
                  });
                }
              })
              .finally(() => {
                that.modelLoading = false;
                that.dialogVisible = false;
              });
          } else {
            API.generalCardPriceAdjustmentCreate(params)
              .then((res) => {
                if (res.StateCode == 200) {
                  that.$message.success("创建成功");
                  that.search();
                } else {
                  that.$message.error({
                    message: res.Message,
                    duration: 2000,
                  });
                }
              })
              .finally(() => {
                that.modelLoading = false;
                that.dialogVisible = false;
              });
          }
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    var that = this;
    that.handleSearch();
    that.entityData();
    that.generalCardAll();
  },
};
</script>
